import React, { useState, useContext, useEffect, useRef } from 'react'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { Button, Menu, MenuItem, useTheme } from '@material-ui/core'
import './ProductViewComponent.css'
import SimilarItem from '../../molecules/ProductCard/SimilarItems'
import Timer from '../../../../product/common/timer'
import Bidding from '@/custom/components/molecules/Bidding/BiddingItem'
import Buynow from '../../molecules/Buynow/BuynowItem'
import CommonContext from '../../../context/common/commonContext'
import ProductContext from '../../../../product/context/product/productContext'
import AuthContext from '../../../../product/context/auth/authContext'
import { dateFormatFront, currencyFormat } from '../../../common/components'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import { dateFormatFrontDay, handleRedirectInternal } from '@/product/common/components'
import { useHistory } from 'react-router-dom'
import BidHistory from '../BidHistory'
import FavouriteCheckbox from '@/custom/components/atoms/FavoriteCheckbox'
import { Swiper, SwiperSlide } from 'swiper/react'
import openSocket from 'socket.io-client'
import AlertContext from '@/product/context/alert/alertContext'
import { messageHandler } from '@/product/common/socketHandler'
import { FreeMode, Pagination, Navigation, Thumbs, Zoom } from 'swiper/modules'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FacebookShareButton } from 'react-share'
import Loaders from '@/custom/components/molecules/Loaders'
import { useTranslation } from 'react-i18next'
import ImageZoom from '@/product/components/organisms/ImageZoom'

const ProductViewComponent = (props) => {
    const [expanded, setExpanded] = useState()
    const [images, setImages] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const { t } = useTranslation()
    const [swiper, setSwiper] = useState(null)

    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)
    const { search_allproducts, getAllSearchProducts } = productContext
    let [bidHistoryChange, setBidHistoryChange] = useState(false)

    const [showNotes, setShowNotes] = useState(false)

    const { setAlert } = useContext(AlertContext)
    const { isAuthenticated, user } = useContext(AuthContext)
    let [viewProduct, setViewProduct] = useState([])
    let [dropdownValue, setDropdownValue] = useState({})
    const {
        allCategory,
        allCondition,
        allDamageTypes,
        allPackageTypes,
        allLocations,
        setBidHistoryValue,
        currentLocation,
        getGlobalVariable,
        configVariables,
        alldata_all_dropdown,
    } = commonContext
    const [product, setProduct] = useState({})
    const history = useHistory()
    const theme = useTheme()

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    const search = {
        limit: 3,
        page: 1,
        orderby: 'p.id, desc',
        order: '',
        market_status: ['open', 'draft', 'closed', 'sold', 'unsold'],
        filters: {
            product_id: {
                value: props.data,
                type: 'in',
                field: 'p.id',
            },
            currentLocation: {
                // state: currentLocation.state,
                // city: currentLocation.city,
            },
        },
    }
    const searchRef = useRef(search)
    const currentImage = useRef(null)
    const [imageIndex, setImageIndex] = useState(0)
    const [imageZoomModal, setImageZoomModal] = useState(false)

    const imageFullScreen = (imgindex) => {
        setImageIndex(imgindex)
    }
    const imgPopup = (popupView) => {
        setImageZoomModal(popupView)
    }

    useEffect(() => {
        getAllSearchProducts(search)
    }, [props.data])

    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
        searchRef.current = search
    })
    useEffect(() => {
        if (alldata_all_dropdown.length == 0) getGlobalVariable()
    }, [])

    const handler = (message, type) => {
        if (type === 'productPosted' || type === 'productClosed') {
            setLoading(true)
            getAllSearchProducts(searchRef.current)
        } else {
            messageHandler(
                message,
                viewProductRef.current,
                userRef.current,
                setAlert,
                setViewProduct,
                type,
            )
        }
    }

    // useEffect(() => {
    //     setProduct(props.data)
    // }, [props.data])

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, {
            transports: ['websocket'],
        })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        socket.on('productPosted', (data) => {
            handler(data, 'productPosted')
        })
        socket.on('productClosed', (data) => {
            handler(data, 'productClosed')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.off('productPosted', (data) => {
                handler(data, 'productPosted')
            })
            socket.off('productClosed', (data) => {
                handler(data, 'productClosed')
            })
            socket.disconnect()
        }
    }, [])

    // 482
    useEffect(() => {
        setViewProduct(search_allproducts.records.length ? search_allproducts.records : [])
        return () => {
            setIsLoading(false)
        }
    }, [search_allproducts])

    console.log(viewProduct, search_allproducts, 'checkProduct')

    useEffect(() => {
        if (viewProduct.length) {
            const value = [...viewProduct][0]
            const dropValue = {}
            console.log(viewProduct, 'vieww')
            setProduct(viewProduct[0])
            alldata_all_dropdown.map((e) => {
                if (e.id == value.conditiontypeid) {
                    dropValue.condition = e.description
                }
                if (e.id == value.category) {
                    dropValue.category = e.description
                }
                if (e.id == value.manufacturers) {
                    dropValue.manufacturers = e.description
                }
            })
            setDropdownValue(dropValue)
            setBidHistoryChange(!bidHistoryChange)
        }
    }, [viewProduct, alldata_all_dropdown])

    console.log('HEREEE=====>', product)
    function isValidURL(url) {
        var urlRegex = new RegExp(
            '^(https?:\\/\\/)?' + // Protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                '((\\d{1,3}\\.){3}\\d{1,3}))' +
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
                '(\\?[;&a-z\\d%_.~+=-]*)?' +
                '(\\#[-a-z\\d_]*)?$',
            'i',
        )

        return urlRegex.test(url)
    }
    useEffect(() => {
        if (product) {
            if (product.allattachmentlist) {
                if (product.allattachmentlist.length) {
                    product.allattachmentlist.forEach(function (data) {
                        let validUrl = isValidURL(data['file_name'])
                            ? data['file_name']
                            : global.images_url + data['file_name']
                        data['original'] = validUrl
                        data['thumbnail'] = validUrl
                        data['main'] = validUrl
                    })
                    setImages(product.allattachmentlist)
                } else {
                    setImages([])
                }
            } else {
                setImages([])
            }
        } else {
            setImages([])
        }
    }, [product])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    const [thumbsSwiper, setThumbsSwiper] = useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const [copyState, setCopyState] = useState({
        value: window.location.host + '/productView/',
        copy: false,
    })

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setAnchorEl(null)
    }

    useEffect(() => {
        // Reinitialize Swiper when the direction changes
        if (swiper) {
            swiper.destroy()
            setSwiper(null)
        }
    }, [theme?.direction])

    const handleSwiper = (swiper) => {
        setSwiper(swiper)
    }
    const loginFn = () => {
        if (product?.id > 0) {
            sessionStorage.setItem('productID', product.id)
        }
        handleRedirectInternal(history, 'login')
    }
    const renderCustomControls = () => {
        if (currentImage.current) {
            const currentIndex = currentImage.current.activeIndex
            console.log(currentIndex, 'currentIndex')
            imageFullScreen(currentIndex)
            console.log(currentImage, 'current')
            if (!images[currentIndex]?.hasOwnProperty('embedUrl')) {
                imgPopup(true)
            }
        }
    }

    const toggleImage = (nav) => {
        if (nav == 'next') {
            if (!images[(imageIndex + 1) % images?.length]?.hasOwnProperty('embedUrl')) {
                setImageIndex((imageIndex + 1) % images?.length)
            }
        } else {
            if (
                !images[(imageIndex + images?.length - 1) % images?.length]?.hasOwnProperty(
                    'embedUrl',
                )
            ) {
                setImageIndex((imageIndex + images?.length - 1) % images?.length)
            }
        }
    }

    return (
        <>
            {isLoading ? (
                <div className="customContainer mt-5">
                    <Loaders name="product_view" isLoading={isLoading} loop={1} />
                </div>
            ) : product ? (
                <div>
                    {/* <div className="pvTop customContainer">
                        {product.market_status === 'open' ? (
                            <FavouriteCheckbox
                                watchlisted={product.watchlistid}
                                project_id={product.id}
                            />
                        ) : null}

                        <Button className="share" onClick={handleClick}>
                            <span className="material-icons">share</span>
                        </Button>
                    </div> */}
                    <div className="pvContainer customContainer">
                        <div className="pvLt">
                            <div className="pvTop">
                                <div className="pcgPoints">
                                    {product.deposit && product.deposit_amount
                                        ? product.deposit_amount
                                        : 0}{' '}
                                    {t('Points')}
                                </div>
                                <div className="pvTopChildCnt">
                                    {product.market_status === 'open' ? (
                                        <FavouriteCheckbox
                                            watchlisted={product.watchlistid}
                                            project_id={product.id}
                                        />
                                    ) : null}

                                    <Button className="share" onClick={handleClick}>
                                        <span className="material-icons">share</span>
                                    </Button>
                                </div>
                            </div>
                            <div className="pvZoomCnt">
                                <img
                                    onClick={renderCustomControls}
                                    src="/assets/svg/zoom_icon.svg"
                                />
                            </div>
                            <Swiper
                                dir={theme?.direction}
                                key={theme?.direction}
                                onSwiper={(swiper) => {
                                    handleSwiper(swiper)
                                    currentImage.current = swiper
                                }}
                                onSlideChange={(swiper) => {
                                    console.log('Current Index:', swiper.activeIndex)
                                }}
                                thumbs={{ swiper: thumbsSwiper }}
                                zoom={true}
                                modules={[Pagination, Navigation, Thumbs, Zoom]}
                                className="mainSwiper"
                                ref={currentImage}
                            >
                                {images?.map((data, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="swiper-zoom-container">
                                            <img src={data?.original} alt={product.title} />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <Swiper
                                onSwiper={setThumbsSwiper}
                                spaceBetween={10}
                                slidesPerView={8}
                                watchSlidesProgress={true}
                                modules={[Navigation, Thumbs]}
                                className="thumbSwiper"
                            >
                                {images?.map((data, index) => (
                                    <SwiperSlide key={index}>
                                        <img src={data?.thumbnail} alt={product.title} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            {/* <ImageGallery
                        items={images}
                        thumbnailPosition="bottom"
                        showNav={true}
                        lazyLoad={true}
                        showBullets={false}
                        showFullscreenButton={true}
                        showPlayButton={false}
                    /> */}
                        </div>
                        <div className="pvRt">
                            {isAuthenticated && (
                                <div className="pvBidStatus">
                                    {product.bidtopstatus === 'outbid' && (
                                        <h4 className="outbid">{t('outbid')}</h4>
                                    )}
                                    {product.bidtopstatus === 'winner' && (
                                        <h4 className="winning">
                                            {' '}
                                            {product.wprice < product.rprice
                                                ? t('reserve_not_met')
                                                : t('winning')}
                                        </h4>
                                    )}
                                    {product.bidtopstatus === 'won' && (
                                        <h4 className="winning">{t('won')}</h4>
                                    )}
                                    {product.bidtopstatus === 'lost' && (
                                        <h4 className="outbid">{t('lost')}</h4>
                                    )}
                                </div>
                            )}
                            <div>
                                <h1 className="pvTitleHeading">
                                    {' '}
                                    {localStorage.i18nextLng === 'for_arabic' &&
                                    product.arabic_title
                                        ? product.arabic_title
                                        : product.title}
                                </h1>
                                <h1 className="pvTitle">
                                    {localStorage.i18nextLng === 'for_arabic' &&
                                    product.arabic_brand
                                        ? product.arabic_brand
                                        : product.brand}
                                </h1>
                                <div
                                    className="pvPriceBtn"
                                    style={{ flexDirection: isAuthenticated ? 'column' : 'row' }}
                                >
                                    <div className="pvPriceCnt">
                                        <div
                                            style={{
                                                background: !isAuthenticated ? 'transparent' : '',
                                            }}
                                            className="pvWpriceCnt"
                                        >
                                            <span>
                                                {' '}
                                                {/* <span className="material-icons">
                                                    electric_bolt
                                                </span>{' '} */}
                                                {t(product.cbidtext)}
                                            </span>{' '}
                                            <span>{currencyFormat(product.wprice, t)}</span>
                                        </div>
                                        <div
                                            style={{
                                                background: !isAuthenticated ? 'transparent' : '',
                                            }}
                                            className="pvWpriceCnt"
                                        >
                                            <span>{`${t('buynow_price')}`}</span>
                                            <span>{currencyFormat(product.bprice, t)}</span>
                                        </div>
                                    </div>

                                    {isAuthenticated ? (
                                        <div
                                            className="pvBidBox"
                                            style={{ marginTop: isAuthenticated ? '24px' : '' }}
                                        >
                                            {product.auction && !product.future_active ? (
                                                <>
                                                    {product.custom_auctionlot_bid_type ===
                                                        'both' ||
                                                    product.custom_auctionlot_bid_type === null ? (
                                                        <>
                                                            <Bidding
                                                                data={product}
                                                                type={'hard'}
                                                                search={search}
                                                                productView={true}
                                                            />
                                                            <Bidding
                                                                data={product}
                                                                type={'proxy'}
                                                                search={search}
                                                                productView={true}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            {product.custom_auctionlot_bid_type ===
                                                            'proxy' ? (
                                                                <Bidding
                                                                    data={product}
                                                                    type={'proxy'}
                                                                    search={search}
                                                                    productView={true}
                                                                />
                                                            ) : (
                                                                <Bidding
                                                                    data={product}
                                                                    type={'hard'}
                                                                    search={search}
                                                                    productView={true}
                                                                />
                                                            )}
                                                            {/* <Bidding
                                                                data={product}
                                                                type={
                                                                    product.custom_auctionlot_bid_type ===
                                                                    'hard'
                                                                        ? 'hard'
                                                                        : product.custom_auctionlot_bid_type ===
                                                                          'proxy'
                                                                        ? 'proxy'
                                                                        : product.custom_auctionlot_bid_type
                                                                }
                                                                search={search}
                                                            /> */}
                                                        </>
                                                    )}
                                                </>
                                            ) : null}
                                            {product.buynow && !product.future_active ? (
                                                <Buynow
                                                    data={product}
                                                    editQuantity={true}
                                                    isOnlyBuynow={
                                                        product.auction === 0 ? true : false
                                                    }
                                                    productView={true}
                                                />
                                            ) : null}
                                        </div>
                                    ) : (
                                        <PrimaryButton
                                            btnSize="mt-3"
                                            label={
                                                <>
                                                    {product.auction
                                                        ? t('login_to_bid')
                                                        : t('login_to_buy')}
                                                    <span
                                                        style={{
                                                            width: 'fit-content',
                                                            fontSize: '22px',
                                                        }}
                                                        className="material-icons"
                                                    >
                                                        chevron_right
                                                    </span>
                                                </>
                                            }
                                            onClick={loginFn}
                                        />
                                    )}
                                </div>
                                {console.log(product, 'productDesc123')}

                                <div className="pvPrimInfo">
                                    <div className="pvAucDetails">
                                        {product.market_status === 'open' ? (
                                            <>
                                                <h4>
                                                    <span>
                                                        {/* <span className="material-icons-outlined">
                                                            timer
                                                        </span> */}
                                                        {product.future_active
                                                            ? t('starts_in')
                                                            : t('time_left')}
                                                    </span>{' '}
                                                    <p>
                                                        <Timer
                                                            date_added={product.date_added}
                                                            date_closed={product.date_closed}
                                                            withText={0}
                                                        />
                                                    </p>
                                                </h4>
                                                {/* <h4>
                                                    <span>
                                                        <span className="material-icons">
                                                            event
                                                        </span>
                                                        {t('Ends')}:
                                                    </span>{' '}
                                                    {dateFormatFront(product.date_closed)}
                                                </h4> */}
                                                {product.buynow ? (
                                                    <>
                                                        <h4>
                                                            <span>
                                                                {/* <span className="material-icons">
                                                                    event
                                                                </span> */}
                                                                {t('Total Quantity')}
                                                            </span>{' '}
                                                            <p>{product.qty - product.sold}</p>
                                                        </h4>
                                                        <h4>
                                                            <span>
                                                                {/* <span className="material-icons">
                                                                    event
                                                                </span> */}
                                                                {t('buynow_price')}
                                                            </span>{' '}
                                                            <p>
                                                                {' '}
                                                                {currencyFormat(product.bprice, t)}
                                                            </p>
                                                        </h4>
                                                    </>
                                                ) : null}
                                                {product.auction ? (
                                                    <>
                                                        <h4>
                                                            <span>
                                                                {/* <span className="material-icons">
                                                                    gavel
                                                                </span> */}
                                                                {t('Bid Increment')}
                                                            </span>{' '}
                                                            <p>{product.incrementamt}</p>
                                                        </h4>
                                                    </>
                                                ) : null}
                                                {product.auction ? (
                                                    <>
                                                        <h4>
                                                            <span>
                                                                {/* <span className="material-icons">
                                                                    gavel
                                                                </span> */}
                                                                {t('bids')}
                                                            </span>{' '}
                                                            <p>{product.bid_count}</p>
                                                        </h4>
                                                    </>
                                                ) : null}
                                            </>
                                        ) : (
                                            <h4>
                                                <span>{t('closed_on')}</span>{' '}
                                                <p>{dateFormatFront(product.date_closed)}</p>
                                            </h4>
                                        )}
                                    </div>
                                </div>

                                <div className="qualityRating">
                                    <h3 className="pvSubtitle">{t('Quality')}</h3>
                                    {/* <div className="pvRating">
                                        {Array.from({ length: 5 }, (_, index) => {
                                            if (index + 1 <= product.rating)
                                                return <span className="material-icons">star</span>
                                            if (
                                                index + 1 - product.rating > 0 &&
                                                index + 1 - product.rating < 1
                                            )
                                                return (
                                                    <span className="material-icons">
                                                        star_half
                                                    </span>
                                                )
                                            else
                                                return (
                                                    <span className="material-icons">
                                                        star_outline
                                                    </span>
                                                )
                                        })}
                                    </div> */}
                                    {/* <span className="material-icons">star</span>
                                <span className="material-icons">star</span>
                                <span className="material-icons">star</span>
                                <span className="material-icons">star_half</span>
                                <span className="material-icons">star_outline</span> */}

                                    {console.log(product, 'dropdownValue')}
                                    <div className="pvQuality">
                                        {dropdownValue?.condition == 'New' ? (
                                            <span style={{ color: 'rgb(5, 21, 39)' }}>
                                                {t('New')}
                                            </span>
                                        ) : // <span className="bad">{t('Used')}</span>
                                        null}
                                        {product?.isfunctional ? (
                                            <span style={{ color: '' }}>{t('No Damages')}</span>
                                        ) : // <span className="bad">{t('Damaged')}</span>
                                        null}
                                        {!product?.ispacked ? (
                                            <span
                                                style={{ background: '#F0CBCC', color: '#B8090C' }}
                                            >
                                                {t('Not in package')}
                                            </span>
                                        ) : // <span className="bad">{t('Not in Package')}</span>
                                        null}
                                        {!product?.isassembled ? (
                                            <span
                                                style={{ background: '#F2E1C9', color: '#C27100' }}
                                            >
                                                {t('Assembly Required')}
                                            </span>
                                        ) : // <span className="bad">{t('Assembly Required')}</span>
                                        null}
                                        {product?.missing_part ? (
                                            <span className="bad">{t('Missing Parts')}</span>
                                        ) : // <span>{t('No Missing Parts')}</span>
                                        null}
                                    </div>
                                </div>

                                <div className="pvItemDetails">
                                    <h3 className="pvSubtitle">{t('Item Details')}:</h3>
                                    <div className="pvDescChild">
                                        {/* {localStorage.i18nextLng === 'for_arabic' &&
                                        product?.desc_proc_arabic ? (
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: product?.desc_proc_arabic,
                                                }}
                                            ></p>
                                        ) : (
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: product?.desc_proc,
                                                }}
                                            ></p>
                                        )} */}

                                        <h6>
                                            <span>{t('Suggested Retail Price')}</span>
                                            {currencyFormat(product.retail_price, t)}
                                        </h6>
                                        {/* <h6>
                                            <span>{t('buyer_premium')}:</span>
                                            {configVariables?.buyer_premium
                                                ? configVariables.buyer_premium
                                                : product?.buyer_premium}
                                            %
                                        </h6> */}
                                        <h6>
                                            <span>{t('Inventory Number')}</span>
                                            {product.inventory_number
                                                ? product.inventory_number
                                                : '-'}
                                        </h6>
                                        <h6>
                                            <span>{t('category')}</span>
                                            {dropdownValue?.category
                                                ? dropdownValue?.category
                                                : '-'}
                                        </h6>
                                        <h6>
                                            <span>{t('condition')}</span>
                                            {dropdownValue?.condition
                                                ? dropdownValue?.condition
                                                : '-'}
                                        </h6>
                                        <h6>
                                            <span>{t('SKU Number')}</span>
                                            {product.sku_no ? product.sku_no : '-'}
                                        </h6>
                                        <h6>
                                            <span>{t('Manufacture')}</span>
                                            {dropdownValue.manufacturers
                                                ? dropdownValue.manufacturers
                                                : '-'}
                                        </h6>
                                    </div>
                                </div>
                                {isAuthenticated ? (
                                    <div className="pvBidHistory">
                                        <h3 className="pvSubtitle">{t('Bid History')}</h3>

                                        <BidHistory
                                            modal={false}
                                            id={product.id}
                                            refresh={bidHistoryChange}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="pgShare"
            >
                <MenuItem>
                    <CopyToClipboard
                        text={copyState.value + product.id}
                        onCopy={() => {
                            setCopyState({ ...copyState, copy: true })
                            setAlert('Copied Successfully', 'success')
                        }}
                    >
                        <Button className="copyClipboard">
                            <span className="material-icons">content_copy</span>
                            {t('Copy Link')}
                        </Button>
                    </CopyToClipboard>
                </MenuItem>
                <MenuItem>
                    <FacebookShareButton url={copyState.value + product.id} quote={product.title}>
                        <span className="fab fa-facebook"></span>
                        Facebook
                    </FacebookShareButton>
                </MenuItem>
            </Menu>

            <ImageZoom
                toggleImage={toggleImage}
                imageZoomModal={imageZoomModal}
                images={images}
                imageIndex={imageIndex}
                setImageZoomModal={setImageZoomModal}
            />
        </>
    )
}
export default ProductViewComponent
